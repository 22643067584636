import React from "react"
import { Grid, GridProps } from "@chakra-ui/react"

import FeaturedProductsItem from "@app/components/Sections/FeaturedProducts/FeaturedProductsItem"

import type { HandleTrackingClickType } from "@app/hoc/Section"
import type { NormalisedProduct } from "@root/types/custom-types/Product/Product"

type Props = GridProps & {
  items: NormalisedProduct[] | undefined
  handleTrackingClick: HandleTrackingClickType
}

const FeaturedProductsGrid: React.FC<Props> = ({ items, handleTrackingClick, ...props }) => {
  const itemsFirst = items?.slice(0, 4) || []

  return items?.length ? (
    <Grid templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }} columnGap={{ base: "4" }} rowGap={{ base: "7" }} {...props}>
      {itemsFirst.map((item, index) => (
        <FeaturedProductsItem
          key={item.handle}
          item={item}
          itemPosition={index}
          itemListName="Featured Products Grid"
          handleTrackingClick={handleTrackingClick}
        />
      ))}
    </Grid>
  ) : null
}

export default React.memo(FeaturedProductsGrid)
